/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from 'react';
import $ from 'jquery';
import { Link, NavLink } from 'react-router-dom';
import { ModelContext } from './context.js';
import mssIcon from '../assets/facebook-messenger-logo-png-44107.png';

function Header() {
    const context = useContext(ModelContext);

    useEffect(() => {
        var element = document.getElementsByClassName("cardList")
        for (var index = 0; index < element.length; index++) {
          var tag = document.createElement('a')
          var text = document.createTextNode("click here")
          tag.setAttribute("href", "https://forms.office.com/Pages/ResponsePage.aspx?id=MsVioQx6RUaW_SDQGc9OWtj8bsVwDsFFlQvcKPGB79FUNUFBWUJBTU9JRFZHRllBVE9NTFVPMjFBSS4u")
          tag.setAttribute("class", "clickHere")
          tag.appendChild(text)
        }
        $('.main-nav-mobile').hide();
        $('.sub-dropdown-content').hide();
        $('.list-dropdown-content').hide();
    });
    const test = () => {
    };



    return (
        <>
            <header>
                <img onClick={test} className="logo" src={context.headerData.logo} al=""t />
                <a href="#/" onClick={() => $('.main-nav-mobile').fadeToggle(200)} className="icon">
                    <div></div>
                    <div></div>
                    <div></div>
                </a>
                <nav className="main-nav-mobile">
                    <ul>
                        <li><a onClick={() => $('.main-nav-mobile').fadeOut(200)} href="#slideShow">{context.headerData.nav.home}</a></li>
                        <li><a onClick={() => $('.main-nav-mobile').fadeOut(200)} href="#aboutUs-section">{context.headerData.nav.aboutUs}</a></li>
                        {/* <li><a href="#programs" onClick={() => { $('.amman-container').fadeIn(); $('.irbid-container').fadeOut(); $('.main-nav-mobile').fadeOut(200); $('.amman-btn').addClass('clicked'); $('.irbid-btn').removeClass('clicked'); $('.program-button').fadeIn(); }}>{context.headerData.nav.programs[0]}</a></li>
                        <li><a href="#programs" onClick={() => { $('.irbid-container').fadeIn(); $('.amman-container').fadeOut(); $('.main-nav-mobile').fadeOut(200); $('.irbid-btn').addClass('clicked'); $('.amman-btn').removeClass('clicked'); $('.program-button').fadeIn(); }}>{context.headerData.nav.programs[1]}</a></li> */}
                    <li className="dropdown sub-dropdown">
  <button onClick={() => $('.sub-dropdown-content.programs-dropdown-content').fadeToggle(200)} className="dropbtn sub-dropdown-btn" type="button">
    {context.headerData.nav.programsTitle || "التخصصات"} <i className="fa fa-caret-down"></i>
  </button>
</li>

<li className="sub-dropdown-content programs-dropdown-content">
  <a
    className="a-sdc analytics-button"
    href="#programs"
    onClick={() => {
      $('.amman-container').fadeIn();
      $('.irbid-container').fadeOut();
      $('.amman-btn').addClass('clicked');
      $('.irbid-btn').removeClass('clicked');
      $('.program-button').fadeIn();
    }}
  >
    {context.headerData.nav.programs[0]}
  </a>

  <a
    className="a-sdc analytics-button"
    href="#programs"
    onClick={() => {
      $('.irbid-container').fadeIn();
      $('.amman-container').fadeOut();
      $('.irbid-btn').addClass('clicked');
      $('.amman-btn').removeClass('clicked');
      $('.program-button').fadeIn();
    }}
  >
    {context.headerData.nav.programs[1]}
  </a>
</li>

                        <li className="dropdown sub-dropdown"><button onClick={() => $('.sub-dropdown-content').fadeToggle(200)} className="dropbtn sub-dropdown-btn" type="scall">{context.headerData.nav.studentServicesHeader}<i className="fa fa-caret-down"></i></button></li>
                        <li className="sub-dropdown-content" >
                            <a target='_blank' rel="noopener noreferrer" className="a-sdc analytics-button" href="https://studentsts-100533.campusnexus.cloud/Login.aspx?ReturnUrl=%2f%3fwa%3dwsignin1.0%26wtrealm%3dhttps%253a%252f%252fsisportal-100533.campusnexus.cloud%252fCMCPortal%252f%26wct%3d2025-01-28T09%253a30%253a07Z%26wctx%3drm%253d0%2526id%253dpassive%2526ru%253dsecure%252fstudent%252fstuportal.aspx%26wreply%3dhttps%253a%252f%252fsisportal-100533.campusnexus.cloud%252fCMCPortal%252f%26AppType%3dPortal%26Role%3dSTUDENT&wa=wsignin1.0&wtrealm=https%3a%2f%2fsisportal-100533.campusnexus.cloud%2fCMCPortal%2f&wct=2025-01-28T09%3a30%3a07Z&wctx=rm%3d0%26id%3dpassive%26ru%3dsecure%2fstudent%2fstuportal.aspx&wreply=https%3a%2f%2fsisportal-100533.campusnexus.cloud%2fCMCPortal%2f&AppType=Portal&Role=STUDENT">{context.headerData.nav.studentServices.selfServicePortal}</a>
                            <a target='_blank' rel="noopener noreferrer" className="a-sdc analytics-button" href="https://lms.ltuc.edu.jo/moodle/">{context.headerData.nav.studentServices.desireToLearn}</a>
                            {/* <a target='_blank' rel="noopener noreferrer" className="a-sdc analytics-button" href="https://employmenthub.ltuc.com">{context.headerData.nav.studentServices.EducationForEmployment}</a> */}
                            {/* <a className='a-sdc analytics-button' target='_blank' rel="noopener noreferrer" href="https://drive.google.com/drive/folders/1Yjv19A-TKR6WIjr3cayI3kKcmonF4hNF">كيّفية استخدام منصات التعليم</a> */}
                        </li>

                        {context.headerData.nav.AcademicCalendar && (
  <li>
    <a 
      onClick={() => $('.main-nav-mobile').fadeOut(200)}
      className='click-close'
      target='_blank' 
      rel="noopener noreferrer" 
      href={context.headerData.nav.AcademicCalendar.link}>
      {context.headerData.nav.AcademicCalendar.text}
    </a>
  </li>
)}
                        <li><a onClick={() => $('.main-nav-mobile').fadeOut(200)} className='click-close' href="#footer">{context.headerData.nav.contactUs.text}</a></li>
                        {/* <li  className="covid-link"> 
                            <Link  to='/covid19'>covid-19</Link>
                        </li> */}
                        {/* <li className='arabic'><a href="#/" onClick={() => getData(context.lang)}>{context.lang === 'Arabic' ? 'العربية' : context.lang}&nbsp;<i className="fa fa-globe"></i></a></li> */}
    
                    </ul>
                </nav>
                {/* -------------------------------------------- Main------------------------------------------------------------- */}
                <nav className="main-nav">
                    <ul>
                        <li><a className='main-link' href="#slideShow">{context.headerData.nav.home}</a></li>
                        <li><a className='main-link' href="#aboutUs-section">{context.headerData.nav.aboutUs}&nbsp;</a></li>

                        {/* <li><a className='main-link' href="#programs" onClick={() => { $('.amman-container').fadeIn(); $('.irbid-container').fadeOut(); $('.amman-btn').addClass('clicked'); $('.irbid-btn').removeClass('clicked'); $('.program-button').fadeIn(); }}>{context.headerData.nav.programs[0]}</a></li>
                        <li><a className='main-link' href="#programs" onClick={() => { $('.irbid-container').fadeIn(); $('.amman-container').fadeOut(); $('.irbid-btn').addClass('clicked'); $('.amman-btn').removeClass('clicked'); $('.program-button').fadeIn(); }}>{context.headerData.nav.programs[1]}</a></li> */}

<li className="dropdown">
  <button className="dropbtn" type="button">
    {context.headerData.nav.programsTitle || "التخصصات"}
  </button>
  <div className="dropdown-content">
    <a
      className="analytics-button"
      href="#programs"
      onClick={() => {
        $('.amman-container').fadeIn();
        $('.irbid-container').fadeOut();
        $('.amman-btn').addClass('clicked');
        $('.irbid-btn').removeClass('clicked');
        $('.program-button').fadeIn();
      }}
    >
      {context.headerData.nav.programs[0]}
    </a>

    <a
      className="analytics-button"
      href="#programs"
      onClick={() => {
        $('.irbid-container').fadeIn();
        $('.amman-container').fadeOut();
        $('.irbid-btn').addClass('clicked');
        $('.amman-btn').removeClass('clicked');
        $('.program-button').fadeIn();
      }}
    >
      {context.headerData.nav.programs[1]}
    </a>
  </div>
</li>

                        <li className="dropdown">
                            <button className="dropbtn" type="scall">{context.headerData.nav.studentServicesHeader}</button>
                            <div className="dropdown-content">
                                <a className='analytics-button' target='_blank' rel="noopener noreferrer" href="https://studentsts-100533.campusnexus.cloud/Login.aspx?ReturnUrl=%2f%3fwa%3dwsignin1.0%26wtrealm%3dhttps%253a%252f%252fsisportal-100533.campusnexus.cloud%252fCMCPortal%252f%26wct%3d2025-01-28T09%253a30%253a07Z%26wctx%3drm%253d0%2526id%253dpassive%2526ru%253dsecure%252fstudent%252fstuportal.aspx%26wreply%3dhttps%253a%252f%252fsisportal-100533.campusnexus.cloud%252fCMCPortal%252f%26AppType%3dPortal%26Role%3dSTUDENT&wa=wsignin1.0&wtrealm=https%3a%2f%2fsisportal-100533.campusnexus.cloud%2fCMCPortal%2f&wct=2025-01-28T09%3a30%3a07Z&wctx=rm%3d0%26id%3dpassive%26ru%3dsecure%2fstudent%2fstuportal.aspx&wreply=https%3a%2f%2fsisportal-100533.campusnexus.cloud%2fCMCPortal%2f&AppType=Portal&Role=STUDENT">{context.headerData.nav.studentServices.selfServicePortal}</a>
                                <a className='analytics-button' target='_blank' rel="noopener noreferrer" href="https://lms.ltuc.edu.jo/moodle/">{context.headerData.nav.studentServices.desireToLearn}</a>
                                {/* <a className='analytics-button' target='_blank' rel="noopener noreferrer" href="https://employmenthub.ltuc.com">{context.headerData.nav.studentServices.EducationForEmployment}</a> */}
                                {/* <a className='analytics-button' target='_blank' rel="noopener noreferrer" href="https://drive.google.com/drive/folders/1Yjv19A-TKR6WIjr3cayI3kKcmonF4hNF">كيّفية استخدام منصات التعليم</a> */}

                            </div>
                        </li>
                        <li>
  <a className='main-link' 
     target='_blank' 
     rel="noopener noreferrer" 
     href={context.headerData.nav.AcademicCalendar.link}>
    {context.headerData.nav.AcademicCalendar.text}
  </a>
</li>
                        <li><a className='main-link' href="#footer">{context.headerData.nav.contactUs.text}</a></li>
                        
                         {/* <li><a className='main-link' href="#header">{context.headerData.nav.AcademicCalendar.text}</a></li> */}
                        {/* <li className="covid-link"> 
                            <Link  to='/covid19'>covid-19</Link>
                        </li> */}
                        {/* <li className="translation arabic"><a href="#/" onClick={() => getData(context.lang)}>{context.lang === 'Arabic' ? 'العربية' : context.lang}&nbsp;<i className="fa fa-globe"></i></a></li> */}
                       
                    </ul>
                </nav>
                
                
           
            </header>
          

            <div className="clearFix"></div>
        </>
    );
}

export default Header;